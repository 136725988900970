@import '~bootstrap/scss/bootstrap.scss';

// Typography
h1 {
	@include h1Fonts();
}

h2 {
	@include h2Fonts();
}

h3 {
	@include h3Fonts();
}

p,
.form-control {
	@include textBody();
}

small {
	line-height: 1 !important;
}

// Nav
.navbar {
	@media only screen and (min-width: 768px) {
		padding: 0.75em 40px 0;
	}
}

.navbar-brand {
	margin-right: 0;
}

.nav-item {
	display: flex;
	align-items: center;
}

.nav-link,
.dropdown-item {
	@include textBody();
	margin-bottom: 0;
	cursor: pointer;
}

// Forms
.dropdown-item.active,
.dropdown-item:active {
	background-color: $lightGrey;
}

// Colors
.bg-lightred {
	background-color: #dc354533 !important;
}

// UI
.card {
	border: 0;
	z-index: 1;

	.card-title {
		font-size: 14px;
		line-height: 1.4;
	}

	.card-header {
		padding: 0.75rem;
		background-color: $white;
	}

	.card-body {
		border-radius: 0.25em;
	}
}

.table {
	border-top: 0;

	thead {
		border-bottom: 0;
		font-weight: normal;
		font-size: 13px;

		th {
			border-bottom: 1px solid $darkestGrey;
			border-top: 0;

			p {
				font-family: $circularBook;
				font-weight: initial;
			}
		}
	}

	td {
		border-top: 1px solid $grey;
	}
}

.bg-grey {
	background-color: $grey;
}

.btn-outline-grey {
	color: $grey;
	border-color: $grey;

	&:hover {
		color: $white;
		background-color: $grey;
	}
}

.dropdown-item:hover,
.dropdown-item:focus {
	color: #212529 !important;
}

.badge {
	font-weight: normal;
}

.alert-dismissible .close {
	padding: 0.5rem 1.25rem;
}

.overflow-scroll {
	overflow: scroll;
}

.container-fluid {
	@media only screen and (min-width: 768px) {
		padding-right: 40px;
		padding-left: 40px;
	}
}

// breadcrumbs
.breadcrumb {
	background: transparent;
	padding: 0;
	font-size: 14px;
	border-radius: 0;
}

a.breadcrumb-item {
	color: $darkestGrey;
	padding: 0;
}
