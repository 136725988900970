// fonts
@font-face {
	font-family: Circular-Book;
	src: url('/fonts/CircularStd-Book.eot');
	src: url('/fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
		url('/fonts/CircularStd-Book.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: Circular-Medium;
	src: url('/fonts/CircularStd-Medium.eot');
	src: url('/fonts/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
		url('/fonts/CircularStd-Medium.otf') format('opentype');
	font-weight: 600;
	font-style: normal;
}

$circularBook: Circular-Book, sans-serif;
$circularMedium: Circular-Medium, sans-serif;

// font sizes
$fsH1: 40px;
$fsH2: 28px;
$fsH3: 22px;
$fsTextBody: 14px;
