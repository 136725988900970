// headings
@mixin h1Fonts {
	font-family: $circularMedium;
	font-size: $fsH1;
}

@mixin h2Fonts {
	font-family: $circularMedium;
	font-size: $fsH2;
}

@mixin h3Fonts {
	font-family: $circularMedium;
	font-size: $fsH3;
}

// text
@mixin textBody {
	font-family: $circularBook;
	font-size: $fsTextBody;
	line-height: 20px;
	text-rendering: optimizeLegibility;
	margin-bottom: 0.5rem;
}
