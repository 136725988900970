@import-normalize;
@import 'common/Fonts';
@import 'common/Variables';
@import 'common/BootstrapOverrides';
@import 'common/Helpers';

html,
body {
	height: 100%;
	position: relative;
	background-color: $lightestGrey;
}

* {
	font-family: $circularBook;
}

.react-calendar {
	border: none;
	border-radius: 0.25rem;
	width: 100%;
}

.react-datepicker-wrapper {
	width: 100%;
}
